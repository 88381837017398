.container-fluid{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.row{
  margin: 0px;
  }    


  @media (max-width: 415px){
    .container{
      min-width:100% !important;
      max-width:100% !important;
    }
   }
   .slider{
    min-height: 350px;
    max-height: 100vh;
    position: relative;
    text-align: center;
    color: white;
   }

   .centered{
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    }
    #card-box-planes{
      min-width: 250px;
    }
    .card-img{
      min-height: 350px;
    }

